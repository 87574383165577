// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import map from 'lodash/map';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText from '~plugins/prismic/components/RichText';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import type { Prismic404Page } from '~schema';

export type ClassKey = 'root' | 'wrapper' | 'title' | 'description' | 'link';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[200],
    backgroundClip: 'content-box',
    padding: theme.spacing(2),
  },
  wrapper: {},
  title: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
      fontSize: '12.5rem',
    },
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  link: {
    margin: theme.spacing(2),
  },
});

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<Prismic404Page>>,
};

const NotFoundPage = ({ classes, node }: Props) => (
  <div className={classes.root}>
    <Container component={ResPadding} vertical className={classes.wrapper}>
      {node?.data?.title?.text ? (
        <Typography variant="h1" className={classes.title}>
          {node?.data?.title?.text}
        </Typography>
      ) : null}
      {node?.data?.description ? (
        <RichText
          {...node?.data?.description}
          className={classes.description}
        />
      ) : null}
      {map(
        node?.data?.actions,
        (action, index: number) =>
          action && (
            <Button
              key={index}
              component={FieldLink}
              variant="contained"
              color="secondary"
              className={classes.link}
              field={action?.action_link}
              aria-label={action?.action_label?.text}
            >
              {action?.action_label?.text || ''}
            </Button>
          ),
      )}
    </Container>
  </div>
);

export default compose(
  withNodePage<Prismic404Page, *>({
    getNode: data => data?.prismic404Page,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(NotFoundPage);

export const query = graphql`
  query NotFoundPageQuery($prismicLocaleId: String) {
    prismic404Page(lang: { eq: $prismicLocaleId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        title {
          text
        }
        description {
          html
        }
        actions {
          action_label {
            text
          }
          action_link {
            link_type
            url
            target
          }
        }
      }
    }
  }
`;
